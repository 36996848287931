export const zhCN = {
  translation: {
    "title/Crossnote": "交叉笔记",
    "search/notes": "搜索笔记",
    "search/notebooks": "搜索笔记本",
    "search/attachments": "搜索附件",

    // Account
    "account/Anonymous": "匿名",
    "account/anonymous": "匿名",

    // General
    "general/go-back": "回退",
    "general/sending": "发送中",
    "general/send": "发送",
    "general/cancel": "取消",
    "general/add": "添加",
    "general/adding": "正在添加",
    "general/backed-up-at": "备份于",
    "general/created-at": "创建于",
    "general/modified-at": "修改于",
    "general/interacted-at": "互动于",
    "general/date-created": "创建时间",
    "general/date-modified": "修改时间",
    "general/date-interacted": "互动时间",
    "general/title": "标题",
    "general/saved-at": "保存于",
    "general/loading": "读取中 ...",
    "general/loading-notifications": "正在读取消息 ...",
    "general/failed-to-set": "设置失败",
    "general/update": "更新",
    "general/updated": "已更新",
    "general/updating": "正在更新",
    "general/close": "关闭",
    "general/Note": "笔记",
    "general/notes": "笔记",
    "general/Settings": "设置",
    "general/Collaborators": "协作者",
    "general/collaborators": "协作者",
    "general/Backups": "备份",
    "general/backups": "备份",
    "general/Discussion": "讨论",
    "general/discussion": "讨论",
    "general/Notifications": "消息",
    "general/Username": "用户名",
    "general/Email": "邮箱",
    "general/Password": "密码",
    "general/confirm-password": "再次确认密码",
    "general/Continue": "下一步",
    "general/enter-username": "输入用户名",
    "general/vickymd": "VickyMD(微记)",
    "general/tags": "标签",
    "general/add-a-tag": "添加一个标签",
    "general/private": "私有",
    "general/public": "公开可见",
    "general/friends-only": "仅好友可见",
    "general/anonymous": "匿名",
    "general/onymous": "公开署名",
    "general/Tag": "标签",
    "general/no-tags": "没有标签",
    "general/Directory": "目录",
    "general/Untitled": "无标题",
    "general/untitled": "无标题",
    "general/Unknown": "未知",
    "general/unknown": "未知",
    "general/Agree": "同意",
    "general/Disagree": "不同意",
    "general/Print": "打印",
    "general/Share": "分享",
    "Editor": "编辑者",
    "EDITOR": "编辑者",
    "Viewer": "阅读者",
    "VIEWER": "阅读者",
    "Admin": "管理员",
    "ADMIN": "管理员",
    "ARCHIVED": "归档的笔记",
    "ATTACHED NOTES": "附加的笔记",
    "DELETED": "删除的笔记",
    "PINNED": "置顶",
    "OTHERS": "其它",
    "PUBLIC NOTES": "公开的笔记",
    "FOLLOWINGS' NOTES": "关注人的笔记",
    "SHARED WITH ME": "分享给我的",
    "BOOKMARKS": "收藏",
    "MY TAGS": "我的标签",
    "general/enter-fullscreen-mode": "进入全屏模式",
    "general/enter-presentation-mode": "进入幻灯片模式",
    "general/math-preview": "数学预览",
    "general/this-note-is-empty": "这个笔记没有内容",
    "general/this-notebook-is-empty": "这个笔记本没有内容",
    "general/nothing-here": "这里什么也没有",
    "general/no-notes-found": "没有找到其它的笔记",
    "general/no-more-attachments-found": "没有找到其它的附件",
    "general/no-more-notebooks-found": "没有找到其它的笔记本",
    "general/no-notifications-found": "没有找到其它的消息",
    "general/clear-all": "清空所有",
    "general/Save": "保存",
    "general/Delete": "删除",
    "general/Follow": "关注",
    "general/Following": "正在关注",
    "general/Unfollow": "取消关注",
    "general/my-notes": "我的笔记",
    "general/public-notes": "公开的笔记",
    "general/followings-notes": "关注人的笔记",
    "general/shared-with-me": "分享给我的",
    "general/Bookmarks": "收藏",
    "general/Archive": "归档",
    "general/Trash": "废纸篓",
    "general/new-note": "新的笔记",
    "general/new-attachment": "新的附件",
    "general/profile-updated": "用户信息已更新",
    "general/ID": "ID",
    "general/Audio": "音频",
    "general/Video": "视频",
    "general/source-url": "源链接",
    "general/sign-in": "登录",
    "general/sign-up": "注册",
    "general/Link": "链接",
    "general/Upload": "上传",
    "general/Uploading": "正在上传",
    "general/clicks": "点击",
    "general/Processing": "正在处理...",
    "general/please-wait": "请稍候...",
    "general/please-sign-in-first": "请先登录",
    "general/Languages": "语言",
    "general/track": "跟踪",
    "general/Open": "打开",
    "general/Download": "下载",
    "general/Configure": "配置",
    "general/Update": "更新",
    "general/empty": "空的",
    "general/Notebooks": "笔记本",
    "general/add-a-notebook": "添加一个笔记本",
    "general/add-a-note": "添加一个笔记",
    "general/add-an-attachment": "添加一个附件",
    "general/configure-the-notebook": "设置笔记本",
    "general/notebook-name": "笔记本名称",
    "general/git-repository": "git 仓库",
    "general/optional": "可选",
    "general/url": "Url",
    "general/branch": "分支",
    "general/remember-username-and-password": "记住用户名和密码",
    "general/stored-locally": "储存于本地",
    "general/cors-proxy": "CORS 代理",
    "general/why-cors-proxy": "为什么需要 CORS 代理？",
    "general/today": "今天",
    "general/todo": "待办事项",
    "general/wiki": "维基",
    "general/tagged": "标签",
    "general/untagged": "未标签",
    "general/encrypt": "加密",
    "general/encrypted": "已加密",
    "general/decrypt": "解密",
    "general/conflicted": "冲突",
    "general/attachments": "附件",
    "general/Pin": "置顶",
    "general/Encryption": "加密",
    "general/Fullscreen": "全屏",
    "general/change-file-path": "更改文件路径",
    "general/create-a-copy": "创建一个副本",
    "general/restore-checkout": "恢复 (git checkout)",
    "general/download-pull": "下载 (git pull)",
    "general/upload-push": "上传 (git pull & git push)",
    "general/disable-the-encryption-on-this-note": "解除这个笔记上的加密",
    "general/encrypt-this-note-with-password": "通过密码加密这个笔记",
    "general/disable-encryption": "解除加密",
    "general/decrypt-this-note": "解密这个笔记",
    "general/upload-the-profile": "上传账户信息",
    "general/commit-message": "提交说明",
    "general/upload-notebook": "上传笔记本",
    "general/Comments": "评论",
    "general/Source": "源",
    "general/Description": "描述",
    "general/Edit": "编辑",
    "general/Rename": "重命名",
    "general/rename-directory": "重命名目录",
    "general/delete-directory": "删除目录",
    "general/rename-tag": "重命名标签",
    "general/delete-tag": "删除标签",
    "general/notebook-updates-found": "发现笔记本更新",
    "general/update-the-notebook": "更新这个笔记本",
    "general/check-notebook-updates-periodically": "周期性地检查笔记本更新",
    "general/minutes": "分钟",
    "general/hard-reset": "硬重置",
    "general/Default": "默认",
    "general/Vim": "Vim",
    "general/Emacs": "Emacs",
    "general/Desc": "降序",
    "general/Asc": "升序",

    // Error
    "error/authentication-failed": "认证失败",
    "error/failed-to-upload-notebook": "上传笔记本失败",
    "error/failed-to-download-notebook": "下载笔记本失败",
    "error/notebook-already-exists": "笔记本已经存在",
    "error/invalid-git-url-prefix": "无效的 git URL 前缀",
    "error/please-resolve-conflicts": "请先解决冲突",
    "error/failed-to-disable-encryption": "解除加密失败",
    "error/decryption-failed": "解密失败",
    "error/failed-to-change-file-path": "更改文件路径失败",
    "error/failed-to-create-widget": "创建挂件失败",
    "error/failed-to-load-widget": "读取挂件失败",
    "error/failed-to-update-the-notebook": "更新笔记本失败",

    // Success
    "success/notebook-uploaded": "笔记本已上传",
    "success/notebook-downloaded": "笔记本已下载",

    // Info
    "info/downloading-notebook": "正在下载笔记本",

    // Note
    "note/unarchive-note": "取消归档",
    "note/archive-note": "归档",
    "note/delete-forever": "永久删除",
    "note/delete-note": "删除",
    "note/restore-note": "恢复",
    "note/report-note": "举报",
    "note/open-in-crossnote-website": "在交叉笔记网站中打开",

    // NoteTags
    "note-tags/send-suggestion-failure": "发送建议失败",
    "note-tags/suggestion-sent": "建议已发送",
    "note-tags/suggest-a-tag-to-this-note": "给这个笔记建议一个标签",

    // UserTags
    "user-tags/follow-a-tag": "关注一个标签",

    // Notifications
    "notifications/refresh": "刷新来查看新的消息",
    "notifications/new-version-available": "发现了新版本的交叉笔记！",

    // Settings
    "settings/about-this-project": "关于这个项目",
    "settings/issues-and-feature-requests": "发现了问题或者有功能请求？",
    "settings/uploading-cover-image": "上传封面图片",
    "settings/upload-image-failure": "上传图片失败",
    "settings/Cover": "封面图片",
    "settings/enter-cover-image-url": "输入封面图片链接",
    "settings/upload-image": "上传图片",
    "settings/Avatar": "头像",
    "settings/enter-avatar-image-url": "输入头像图片链接",
    "settings/Name": "名字",
    "settings/enter-name": "输入你的名字（这个不是你的用户名）",
    "settings/editor-cursor-color": "编辑器光标颜色",
    "settings/hello": "你好，",
    "settings/world": "世界！",
    "settings/linked-with-github-account": "已关联 GitHub 帐号",
    "settings/link-with-github-account": "关联到 GitHub 帐号",
    "settings/log-out": "登出",
    "settings/author-name": "作者姓名",
    "settings/author-email": "作者邮箱",
    "settings/theme": "主题",
    "settings/created-cloud-widgets-count": "创建的云挂件数量",
    "settings/key-map": "键盘映射",

    // Preview
    "preview/attach-a-note": "附加一个笔记",
    "preview/crossnote-untitled": "交叉笔记 - 无标题",
    "preview/edit-this-note": "编辑这个笔记",

    // ProfileCard
    "profile-card/followings": "关注",
    "profile-card/followers": "粉丝",
    "profile-card/Followings": "关注",
    "profile-card/Followers": "粉丝",
    "profile-card/notes": "笔记",
    "profile-card/follow-user-failure": "无法关注这个用户",
    "profile-card/followed-user": "关注了这个用户",
    "profile-card/unfollow-user-failure": "无法取消关注这个用户",
    "profile-card/unfollowed-user": "取消关注了这个用户",

    // TagCard
    "tag-card/pin-to-my-notes-page": "置顶到我的笔记页",

    // Interaction panel
    "interaction-panel/show-attached-notes": "显示附加的笔记",
    "interaction-panel/add-comment": "添加评论",
    "interaction-panel/add-reaction": "添加表情",

    // Editor
    "editor/loading-your-note": "正在读取你的笔记...",
    "editor/sync-failure": "同步失败... 请重启网页",
    "editor/saving": "正在保存...",
    "editor/save-failure": "保存失败",
    "editor/saved": "已保存",
    "editor/update-note-failure": "更新笔记失败",
    "editor/placeholder": "#  你好 😀 输入 '/' 打开命令",
    "editor/ln": "行",
    "editor/col": "列",

    // * NoteControl
    "editor/note-control/allow-readers-to-suggest-note-tags":
      "允许读者向你建议笔记的标签",
    "editor/note-control/allow-readers-to-attach-notes-under-this-note":
      "允许读者在这个笔记下附加笔记",
    "editor/note-control/inherit-parent-note-collaborators-list":
      "继承上一级笔记的协作者列表",
    "editor/note-control/note-visibility-hint":
      "所有的协作者也将能够看到这个笔记",
    "editor/note-control/allow-readers-to-attach-notes-unchecked":
      "适用于你以及所有可编辑或可管理这个笔记的协作者",
    "editor/note-control/allow-readers-to-attach-notes-checked":
      "适用于所有可以看到这个笔记的人",
    "editor/note-control/enter-parent-note-id": "输入上一级笔记的 ID",
    "editor/note-control/attach-note-failure": "附加笔记失败",
    "editor/note-control/unattach-note-failure": "取消附加笔记失败",
    "editor/collaborator-hint": " - 他们将会看到这个笔记",
    "editor/shareable-link-hint":
      "所有拥有这个链接的人无需登录即可查看/修改这个笔记",

    // * BackupsControl
    "editor/backups-control/create-backup": "创建备份",
    "editor/backups-control/delete-this-backup": "删除这个备份",
    "editor/backups-control/create-backup-failure": "创建备份失败",
    "editor/backups-control/backup-created": "备份已创建",
    "editor/backups-control/delete-backup-failure": "删除备份失败",
    "editor/backups-control/backup-deleted": "备份已删除",
    "editor/backups-control/cloud-version": "云端版本",
    "editor/backups-control/backup-name": "备份名称",

    // * CollaboratorasControl
    "editor/collaborators-control/set-collaborator-role-failure":
      "设置协作者角色失败",
    "editor/collaborators-control/add-collaborator-failure": "添加协作者失败",
    "editor/collaborators-control/collaborator-removed": "协作者已删除",
    "editor/collaborators-control/collaborator-added": "协作者已添加",
    "editor/collaborators-control/can-view": "可以浏览",
    "editor/collaborators-control/can-edit": "可以编辑",
    "editor/collaborators-control/can-administrate": "可以管理员管理",

    // * EditorToolBar
    "editor/toolbar/insert-image": "插入图片",
    "editor/toolbar/upload-local-images": "上传本地图片",
    "editor/toolbar/capture-webpage": "截取整个网页",
    "editor/toolbar/insert-clock": "插入时钟",
    "editor/toolbar/insert-emoji": "插入 Emoji",
    "editor/toolbar/insert-media": "插入媒体",
    "editor/toolbar/insert-audio": "插入音频",
    "editor/toolbar/netease-music": "网易云音乐",
    "editor/toolbar/insert-video": "插入视频",
    "editor/toolbar/insert-youtube": "插入 Youtube",
    "editor/toolbar/insert-bilibili": "插入 Bilibili",
    "editor/toolbar/insert-slide": "插入幻灯片",
    "editor/toolbar/insert-ocr": "插入 OCR",
    "editor/toolbar/insert-kanban": "插入看板",
    "editor/toolbar/insert-abc-notation": "插入 ABC notation",
    "editor/toolbar/insert-header-1": "插入一级标题",
    "editor/toolbar/insert-header-2": "插入二级标题",
    "editor/toolbar/insert-header-3": "插入三级标题",
    "editor/toolbar/insert-header-4": "插入四级标题",
    "editor/toolbar/insert-header-5": "插入五级标题",
    "editor/toolbar/insert-header-6": "插入六级标题",
    "editor/toolbar/bold": "粗体",
    "editor/toolbar/italic": "斜体",
    "editor/toolbar/strikethrough": "删除线",
    "editor/toolbar/insert-inline-code": "插入行内代码",
    "editor/toolbar/insert-blockquote": "插入引用",
    "editor/toolbar/insert-unordered-list": "插入无序列表",
    "editor/toolbar/insert-ordered-list": "插入有序列表",
    "editor/toolbar/insert-link": "插入链接",
    "editor/toolbar/insert-table": "插入表格",
    "editor/toolbar/insert-comment": "插入交叉笔记云评论挂件",
    "editor/toolbar/insert-github-gist": "插入 GitHub Gist",

    // * NoteControl
    "editor/note-control/tag-added": "标签已被添加",
    "editor/note-control/tag-deleted": "标签已被删除",
    "editor/note-control/set-note-visibility-failure": "设置笔记可见度失败",
    "editor/note-control/note-visibility-updated": "笔记可见度已更新",
    "editor/note-control/set-author-visibility-failure": "设置作者可见度失败",
    "editor/note-control/author-visibility-updated": "作者可见度已更新",
    "editor/note-control/editor-mode": "编辑器模式",
    "editor/note-control/source-code": "源代码",
    "editor/note-control/preview": "预览",
    "editor/note-control/split-view": "分栏",
    "editor/note-control/pin-preview": "固定预览到右侧",
    "editor/note-control/display-modification-records": "显示修改记录",
    "editor/note-control/note-visibility": "笔记可见度",
    "editor/note-control/author-visibility": "作者可见度",
    "editor/note-control/shareable-link": "可分享的链接",
    "editor/note-control/copy-to-clipboard": "复制到粘贴板",
    "editor/note-control/link-to-preview": "到预览的链接",
    "editor/note-control/link-to-editor": "到编辑器的链接",
    "editor/note-control/attach-to-note": "附加到笔记",
    "editor/note-control/source-added": "链接已被添加",
    "editor/note-control/source-deleted": "链接已被删除",
    "editor/note-control/invalid-note-source": "无效的链接",
    "editor/note-control/add-note-source": "添加相关的链接",
    "editor/note-control/note-source-hint":
      "所有相同链接的笔记将会被聚集到一起",
    "editor/note-control/enter-note-source": "输入链接",

    // AvatarPanel
    "avatar-panel/this-note-is-private": "这个笔记是私有的",
    "avatar-panel/this-note-is-public": "这个笔记是公开可见的",
    "avatar-panel/this-note-is-friends-only": "这个笔记仅对好友可见",
    "avatar-panel/you-are-anonymous": "你是匿名的",

    // FileExporter
    "file-exporter/name": "文件导出",
    "file-exporter/orientation": "方向",
    "file-exporter/portrait": "竖直",
    "file-exporter/landscape": "水平",
    "file-exporter/paper-size": "纸张大小",
    "file-exporter/unit": "单位",
    "file-exporter/margin-top": "上边距",
    "file-exporter/margin-bottom": "下边距",
    "file-exporter/margin-left": "左边距",
    "file-exporter/margin-right": "右边距",

    // Utils
    "utils/uploading-image": "正在上传图片...",
    "utils/upload-image-failure": "上传图片失败",

    // Notification
    // * CommentPostingNotification
    "notification/replies-to-you-in": "回复了你在",
    "notification/'s-note": "的笔记 ",
    // * NoteReactionNotification
    "notification/added": " 添加了",
    "notification/to-your-note": "到你的笔记",
    "notification/add-you-as": "添加了你作为",
    "notification/of-note": "到笔记",
    // * NoteTagSuggestion
    "notification/add-tag-failure": "添加标签失败",
    "notification/suggested-to-add-tag": "建议添加标签",
    // * UserFollowingNotification
    "notification/started-following-you": "开始关注了你",
    // * BookmarkNotification
    "notification/bookmarked-your-note": "收藏了你的笔记",
    "notification/view-more-notifications": "查看更多的消息",

    // Widgets
    "widget/autoplay": "自动播放",
    "widget/controls": "控制器",
    "widget/loop": "循环",
    "widget/muted": "静音",
    "widget/creating-widget": "正在创建挂件",
    "widget/loading-widget": "正在读取挂件",
    "widget/crossnote.image/thanks_sm_ms":
      "在此由衷感谢 sm.ms 提供免费的图床服务。",
    "widget/crossnote.audio/audio_element_fail":
      "你的浏览器不支持音频 audio 元素",
    "widget/crossnote.audio/source-url-placeholder":
      "在这里输入音频文件源链接，然后按 '回车' 键来插入",
    "widget/crossnote.video/video_element_fail":
      "你的浏览器不支持视频 video 元素",
    "widget/crossnote.bilibili/error_message":
      "错误：仅支持 https://www.bilibili.com/video/av79126732 这样的链接",
    "widget/crossnote.youtube/error_message":
      "错误：仅支持 https://www.youtube.com/watch?v=MmB9b5njVbA 这样的链接",
    "widget/crossnote.auth/welcome": "欢迎使用交叉笔记",
    "widget/crossnote.auth/doesnt-have-account": "没有帐号？咱们来创建一个😉！",
    "widget/crossnote.auth/forgot_password": "忘记你的密码了 😣？",
    "widget/crossnote.auth/failed_to_send_code": "发送验证码到邮箱失败",
    "widget/crossnote.auth/verification_code_sent": "验证码已经被发送到 ",
    "widget/crossnote.auth/failed_to_reset_password": "重置密码失败",
    "widget/crossnote.auth/password_reset": "你的密码已被重置",
    "widget/crossnote.auth/password-mismatch": "密码不一致",
    "widget/crossnote.auth/hello": "你好，",
    "widget/crossnote.auth/enter-the-verification-code": "输入验证码",
    "widget/crossnote.auth/get-code": "获取验证码",
    "widget/crossnote.auth/sign-in-with-github-account": "使用 GitHub 账号登录",
    "widget/crossnote.auth/already-have-an-account": "已有帐号？",
    "widget/crossnote.auth/reset-password": "重置密码",
    "widget/crossnote.auth/Or": "或者",
    "widget/crossnote.bilibili/Bilibili": "Bilibili",
    "widget/crossnote.bilibili/bilibili-video-url": "Bilibili 视频链接",
    "widget/crossnote.bilibili/url-placeholder":
      "在这里插入 Bilibili 视频的链接，然后按 '回车' 键来插入",
    "widget/crossnote.image/image-helper": "图片助手",
    "widget/crossnote.image/image-url-placeholder":
      "在这里输入图片链接，然后按 '回车' 键来插入",
    "widget/crossnote.image/click-here-to-browse-image-file":
      "点击这里来选择图片文件",
    "widget/crossnote.neteasemusic/song-id-placeholder":
      "输入网易云音乐歌曲数字 ID",
    "widget/crossnote.video/video-url-placeholder":
      "在这里输入视频文件源链接，然后按 '回车' 键来插入",
    "widget/crossnote.video/poster-url": "封面图片链接（可选填）",
    "widget/crossnote.video/poster-url-placeholder": "在这里输入封面图片链接。",
    "widget/crossnote.youtube/youtube-video-url": "Youtube 视频链接",
    "widget/crossnote.youtube/youtube-video-url-placeholder":
      "在这里插入 Youtube 视频的链接，然后按 '回车' 键来插入",
    "widget/crossnote.ocr/ocr": "OCR - 光学字符识别",
    "widget/crossnote.ocr/local-image": "本地图片",
    "widget/crossnote.ocr/recognize-text-in-languages": "识别以下语言的文字",
    "widget/crossnote.ocr/start-ocr": "开始文字识别",
    "widget/crossnote.ocr/extra-settings": "额外设定",
    "widget/crossnote.ocr/grayscale": "灰度",
    "widget/crossnote.kanban/add-column": "添加新的列",
    "widget/crossnote.github_gist/title": "嵌入一个 GitHub Gist",
    "widget/crossnote/github_gist/enter-github-gist-url":
      "输入 GitHub Gist URL",

    // Tesseract
    "tesseract/loading tesseract core": "正在读取 tesseract 核心",
    "tesseract/initializing tesseract": "正在初始化 tesseract",
    "tesseract/initialized tesseract": "已初始化 tesseract",
    "tesseract/loading language traineddata": "正在读取语言训练数据",
    "tesseract/loaded language traineddata": "已读取语言训练数据",
    "tesseract/initializing api": "正在初始化程序接口",
    "tesseract/initialized api": "已初始化程序接口",
    "tesseract/recognizing text": "正在识别文字",

    // Comment widget
    "widget/crossnote.comment/reply-to-this-user": "回复给这个用户",
    "widget/crossnote.comment/add-reaction-to-this-comment":
      "给这条评论添加反应",
    "widget/crossnote.comment/edit-this-message": "编辑这条评论",
    "widget/crossnote.comment/modified-comment": "编辑过的评论",
    "widget/crossnote.comment/post-comment-failure": "发送评论失败",
    "widget/crossnote.comment/unsubscribe-info": "取消订阅评论",
    "widget/crossnote.comment/subscribe-info": "订阅评论",
    "widget/crossnote.comment/view-more-comments": "查看更多的评论",

    // Dialogs
    "delete-note-dialog/title": "你确定要删除这个笔记？",
    "delete-note-dialog/subtitle": "你无法撤销此操作。",
    "delete-directory-dialog/title": "你确定要删除这个目录？",
    "delete-directory-dialog/subtitle":
      "所有子目录以及笔记也将会被删除。你无法撤销此操作。",
    "delete-tag-dialog/title": "你确定要删除这个标签？",
    "delete-tag-dialog/subtitle": "所有子标签也将会被删除。你无法撤销此操作。",
    "add-notebook-dialog/disclaimer":
      "所有笔记本的数据将只会被保存于浏览器本地以供离线使用。我们不会上传任何你的笔记本数据到我们的服务器。",
    "edit-image-dialog/title": "编辑图片",
    "edit-image-dialog/image-url": "图片 URL",
    "edit-image-dialog/image-title": "图片标题",
    "edit-image-dialog/image-alt-text": "图片替代文本",
    "language-selector-dialog/subtitle": "请选择偏好语言",

    // react-ios-pwa-prompt
    "react-ios-pwa-prompt/copy-title": "添加到主屏幕",
    "react-ios-pwa-prompt/copy-body":
      "这个网站拥有软件功能。将它添加到你的主屏幕来开启全屏以及离线使用功能。",
    "react-ios-pwa-prompt/copy-share-button-label": "1) 点击 '分享' 按钮",
    "react-ios-pwa-prompt/copy-add-home-button-label": "2) 点击 '添加到主屏幕'",

    // git
    "git/status/ignored": "已忽略",
    "git/status/unmodified": "未修改",
    "git/status/*modified": "*已修改",
    "git/status/*deleted": "*已删除",
    "git/status/*added": "*新增",
    "git/status/absent": "缺失",
    "git/status/modified": "已修改",
    "git/status/deleted": "已删除",
    "git/status/added": "新增",
    "git/status/*unmodified": "*未修改",
    "git/status/*absent": "*缺失",
    "git/status/*undeleted": "*未删除",
  },
};
